import React, { useState } from 'react';
import { Row, Column } from '../Grid';
import Image from '../Image';
import Button from '../Button';
import Icon from '../Icon';

const CareerTeaser = ({
  titleOne,
  titleTwo,
  img,
  name,
  textOne,
  italicText,
  textTwo,
  to,
  stat,
}) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <Row
      className="careerProfiles__careers"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Column>
        <h3 className="h3">
          {titleOne}
          <br />
          {titleTwo}
        </h3>
      </Column>
      <Column size={3}>
        <img src={img} className="careerProfiles__headshot" />
      </Column>
      <Column size={6} className="careerProfiles__text">
        <h4>Meet {name}</h4>
        <p>
          {textOne}
          <span className="text-italic">{italicText}</span>
          {textTwo}
        </p>
        <Button className="button--link" to={to}>
          Learn More <Icon name="arrowright" marginLeft />
        </Button>
      </Column>
      <Column size={3} className="careerProfiles__careerHighlight--hover">
        {isShown && <p>{stat}</p>}
      </Column>
    </Row>
  );
};

export default CareerTeaser;
