import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container } from '../components/Grid';
import Image from '../components/Image';
import Subfooter from '../components/Subfooter';
import CareerTeaser from '../components/CareerTeaser';

const CareerProfiles = () => (
  <Layout title="Career Profiles">
    <Section className="about-mission">
      <Container>
        <h1 className="h1">Career Profiles</h1>
        <Image filename="CPmain-image-hero.png" className="about__hero-image" />
        <h2 className="h2 mt-1">
          Meet Diverse Leaders Working toward Health Equity
        </h2>
        <p>
          Take students inside the lives of passionate health professionals who
          leverage STEM skills and strengthen communities to create more
          equitable health outcomes. Dive into each career with a series of
          interactive resources including video vignettes, career profiles, and
          student activities.
        </p>
      </Container>
    </Section>

    {/* Career Profiles */}
    <Section className="careerProfiles">
      <Container>
        <CareerTeaser
          titleOne="Community Health Worker"
          img="/images/global-image-headshot-Joaquin@2x.png"
          name="Joaquin"
          textOne="Experience a day in the life of a Community Health Worker guiding members of the community to the programs and services that will improve their lives and well-being."
          to="/health-worker"
          stat="Meet people in the community where they are to assess and meet their individual needs."
        />
        <CareerTeaser
          titleOne="Patient Screening Operations Manager"
          img="/images/global-image-headshot-Krishna@2x.png"
          name="Krishna"
          textOne="Explore how the Patient Screening Operations Manager role bridges the gap between social worker and healthcare provider, ensuring that patient needs are met holistically."
          to="/operations-manager"
          stat="Provide assistance with utilities, housing, and food in addition to healthcare if needed."
        />
        <CareerTeaser
          titleOne="Manufacturing Scientist"
          img="/images/global-image-headshot-Kim@2x.png"
          name="Kimberly"
          textOne="Find out how a Manufacturing Scientist is able to support a 
              Manufacturing Science department by delivering high-quality,
              innovative products to colleagues at the perfect time."
          to="/manufacturing-scientist"
          stat="Often design, create, conduct, and monitor experiments to make
              better medications and manufacturing protocols."
        />
        <CareerTeaser
          titleOne="Associate Director of Pharmaceutical Sciences"
          img="/images/global-image-headshot-Craig@2x.png"
          name="Craig"
          textOne="Learn about the cutting-edge technology that an Associate Director
              of "
          italicText="Takeda"
          textTwo="'s Smart Lab explores in order to help improve the
              overall capabilities and success of the company."
          to="/pharmaceutical-sciences"
          stat="Run automation and implementation teams overseeing such processes
              as method development and method qualification."
        />
        <CareerTeaser
          titleOne="Pharmaceutical Executive"
          img="/images/global-image-headshot-Charlotte@2x.png"
          name="Charlotte"
          textOne="Go inside the strategic planning and versatile collaboration that
              a Pharmaceutical Executive uses to establish productive working relationships."
          to="/patient-affairs"
          stat="Leading teams to create a more inclusive health care environment
              so everyone, everywhere can reach their full health potential."
        />
      </Container>
    </Section>
    {/* Floating Graphic */}
    <Section className="careerProfiles__graphic-wrap">
      <img
        src="../../images/CPmain-image-molecule.png"
        className="careerProfiles__graphic"
      />
    </Section>
    <Subfooter
      className="subfooter__quote-careers"
      footerImage="CPmain-image-QuoteBox@2x.png"
      footerText="There's no dream that's too big, there's no idea that's too small, for you to make it a reality."
      footerSourceOne={'Charlotte Owens, MD'}
      footerSourceTwo={'Pharmaceutical Executive'}
    />
  </Layout>
);

export default CareerProfiles;
